import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';

import { ToastConfig } from '@core/interfaces';
import { UtilsService } from '@core/services';
import { SolicitaCuentaService } from '@email/email-confirmar/services';
import { GenerarNoteErrors, ModalType } from '@entrega-equipo/interfaces';
import { DireccionEntregaService } from '@entrega-equipo/services';
import { GlobalBodyService, PagoService, PaymentRecalculationsService } from '@pago/services';
import { selectAgente, selectIdEmpleado, selectIdReservacion, selectOficinaID, selectPagosPlazos, selectPhone, selectPutSolicitud, selectRegion, selectSeriePhone } from '@store/index';

@Component({
    selector: 'app-recalculte-down-payment-dialog',
    templateUrl: './recalculte-down-payment-dialog.component.html',
    styleUrls: ['./recalculte-down-payment-dialog.component.scss'],
    standalone: true,
    imports: [DialogModule, SharedModule, ButtonModule, ToastModule, CurrencyPipe]
})
export class RecalculteDownPaymentDialogComponent {
  @Input() showDialog = false;
  @Output() generarNotaWasCalledCorrectly = new EventEmitter<boolean>(false);

  readonly #router = inject(Router);
  readonly #store = inject(Store);
  readonly #solicitaCuenta = inject(SolicitaCuentaService);
  readonly #pagoService = inject(PagoService);
  readonly #utilsService = inject(UtilsService);
  readonly #direccionEntregaService = inject(DireccionEntregaService);
  readonly #paymentRecalculation = inject(PaymentRecalculationsService);
  readonly #globalBodyService = inject(GlobalBodyService);

  readonly #oficinaId = this.#store.selectSignal(selectOficinaID);
  readonly #idEmpleado = this.#store.selectSignal(selectIdEmpleado);
  readonly #agente = this.#store.selectSignal(selectAgente);
  readonly #region = this.#store.selectSignal(selectRegion);

  readonly #pagosPlazos = this.#store.selectSignal(selectPagosPlazos);
  readonly #phone = this.#store.selectSignal(selectPhone);
  readonly #serie = this.#store.selectSignal(selectSeriePhone);
  readonly #idReservacion = this.#store.selectSignal(selectIdReservacion);
  readonly #putSolicitud = this.#store.selectSignal(selectPutSolicitud);

  private plazos: string = sessionStorage.getItem('plazos')!;

  /** Propiedad para configurar el toast error */
  errorToastConfig: ToastConfig;

  get phone() {
    return this.#phone()!;
  }

  get getValues() {
    const { cuotas, enganche, nro_cuotas } = this.#pagosPlazos();
    return {
      enganche: enganche,
      monto: cuotas[0].monto,
      primeraCuota: cuotas[0].vencimiento,
      ultimaCuota: cuotas[cuotas.length - 1].vencimiento,
      totalCuotas: nro_cuotas,
      plazos: this.plazos
    }
  }


  constructor() {
    this.errorToastConfig = this.#utilsService.configuracionErrorToast;
  }


  onSubmit() {
    const payloadGenerarBody = this.payloadGenerarBody();
    this.#pagoService.generateNote(payloadGenerarBody).subscribe({
      next: () => this.callPutSolicitudService(),
      error: (error: Error) => {
        const { message } = error;
        const auxMessage = message.toUpperCase();

        if (auxMessage.includes(GenerarNoteErrors.BESMayorSAP)) {
          const recalcularEnganche = this.#paymentRecalculation.recalcularEngancheYCuotas(auxMessage);

          if (recalcularEnganche) {
            sessionStorage.setItem('showModal', ModalType.recalculateDownPayment);
          } else {
            sessionStorage.setItem('showModal', ModalType.phoneNotAvailable);
          }
        } else if (auxMessage.includes(GenerarNoteErrors.RFCError)) {
          sessionStorage.setItem('showModal', ModalType.rfc);
        } else {
          sessionStorage.setItem('showModal', ModalType.phoneNotAvailable);
        }

        location.reload();
      }
    });
  }

  onCancel() {
    sessionStorage.removeItem('showModal');
    sessionStorage.removeItem('tipoEntrega');
    sessionStorage.removeItem('codigo');

    this.#direccionEntregaService.cancelaPaqueteria().subscribe();
    this.#pagoService.cancelResource(this.cancelBookingBody()).subscribe();
    this.#router.navigate( ['/equipos'], { queryParams: { path: 'equipos'} } );
  }

  private cancelBookingBody() {
    return {
      payload: {
        agente:`${this.#idEmpleado()}|${this.#agente()}`, // Estatico
        codigo:"0", // Estatico
        idReservacion:  this.#idReservacion(), // Es la respuesta del servicio de reservaREcurso reservarRecursoResponse.idReservacion
        region: this.#region(),
        serie: this.#serie(), // Es la respuesta del servicio de reservaRecurso reservarRecursoResponse.recurso[0].serie
        sku: this.phone.codigo,
        cantidad: "1",
        almacen: this.#oficinaId()
     }
    }
  }

  private payloadGenerarBody() {
    const getGenerarNotaBody = this.#globalBodyService.generateGenerarNotaBody();
    return getGenerarNotaBody.generarNota.body;
  }

  private callPutSolicitudService () {
    this.#solicitaCuenta.putSolicitud(this.#putSolicitud()).subscribe({
      next: () => this.generarNotaWasCalledCorrectly.emit(true),
      error: (error: Error) => {
        this.#utilsService.clearToast();
        this.errorToastConfig.detail = error.message;
        this.#utilsService.showToast(this.errorToastConfig);
      }
    })
  }
}
